import * as React from "react"
import FooterPrimary from "./footerPrimary"
import FooterSecondary from "./footerSecondary"

const Footer = () => (
  <footer className="region region--footer">
    <FooterPrimary />
    <FooterSecondary />
  </footer>
)

export default Footer
