import * as React from "react"

const Navigation = ({ children, classes, title }) => {
  return (
    <nav className={`menu ${classes}`}>
      <h2 className="visually-hidden" aria-hidden={true}>
        {title}
      </h2>
      <ul className="menu__list">{children}</ul>
    </nav>
  )
}

export default Navigation
