import * as React from "react"
import { Link } from "gatsby"

const NavItem = ({ children, path, text, subMenu = false }) => (
  <li className="menu__item">
    <Link
      to={path}
      className="menu__link"
      activeClassName="is-active"
      partiallyActive={true}
    >
      {text}
    </Link>
    {subMenu ? <ul className="menu__list">{children}</ul> : null}
  </li>
)

export default NavItem
