import * as React from "react"
import PropTypes from "prop-types"

const FieldText = ({ children, classes }) => (
  <div className={`field field--text${classes ? ` ${classes}` : ""}`}>
    {children}
  </div>
)

FieldText.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default FieldText
