import * as React from "react"
import PropTypes from "prop-types"

const Container = ({ children, classes, title, description, row = false }) => {
  return (
    <div className={`block ${classes}`}>
      {title ? (
        <div className="block__header">
          <h2 className="block__title">{title}</h2>
          {description ? (
            <div className="block__description">{description}</div>
          ) : null}
        </div>
      ) : null}
      <div className={`block__content${row ? " block__row" : ""}`}>
        {children}
      </div>
    </div>
  )
}

Container.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  row: PropTypes.bool,
  classes: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Container
