import * as React from "react"
import Navigation from "../block/navigation"
import NavItem from "../item/navItem"
import Container from "../block/container"
import FieldText from "../field/fieldText"

const FooterSecondary = () => (
  <div className="region region--footer--secondary">
    <div className="region__inner">
      <Container classes="block--disclaimer">
        <FieldText>
          <p>© {new Date().getFullYear()} Sideline. All Rights Reserved</p>
        </FieldText>
      </Container>

      <Navigation title="Static navigation" classes="menu--static">
        <NavItem path="#" text="Terms of Use" />
        <NavItem path="#" text="Privacy Policy" />
        <NavItem path="#" text="Legal Notice" />
        <NavItem path="/sitemap" text="Sitemap" />
      </Navigation>

      <Container classes="block--social-networks">
        <div className="social__icon">
          <a
            href="https://www.facebook.com/sidelineHQ"
            className="social__link social__link--facebook"
            target="_blank"
            rel="noreferrer noopener"
          >
            Facebook
          </a>
        </div>
        <div className="social__icon">
          <a
            href="https://twitter.com/sidelinehq"
            className="social__link social__link--twitter"
            target="_blank"
            rel="noreferrer noopener"
          >
            Twitter
          </a>
        </div>
        <div className="social__icon">
          <a
            href="https://www.linkedin.com/showcase/sideline-number"
            className="social__link social__link--linkedin"
            target="_blank"
            rel="noreferrer noopener"
          >
            LinkedIn
          </a>
        </div>
        <div className="social__icon">
          <a
            href="https://www.instagram.com/sidelinehq"
            className="social__link social__link--instagram"
            target="_blank"
            rel="noreferrer noopener"
          >
            Instagram
          </a>
        </div>
      </Container>
    </div>
  </div>
)

export default FooterSecondary
