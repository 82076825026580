import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Navigation from "../block/navigation"
import NavItem from "../item/navItem"
import Container from "../block/container"

const FooterPrimary = () => (
  <div className="region region--footer--primary">
    <div className="region__inner">
      <Container classes="block--footer-logo">
        <StaticImage
          as="figure"
          className="field field--image"
          src="../../assets/svg/logo-footer.svg"
          quality="90"
          alt="Sideline"
          placeholder="tracedSVG"
        />
      </Container>

      <Navigation title="Footer navigation" classes="menu--footer">
        <NavItem path="#" text="Product" subMenu={true}>
          <NavItem path="/features" text="Features" />
          <NavItem path="/use-cases" text="Who it's For" />
          <NavItem path="/pricing" text="Pricing" />
        </NavItem>
        <NavItem path="/company" text="Company" subMenu={true}>
          <NavItem path="#" text="About" />
          <NavItem path="#" text="Press Kit" />
          <NavItem path="/faq" text="FAQ" />
        </NavItem>
        <NavItem path="/contact" text="Contact" subMenu={true}>
          <NavItem path="#" text="Book a demo" />
          <li className="menu__item">
            <a href="mailto:hello@sideline.com" className="menu__link">
              hello@sideline.com
            </a>
          </li>
        </NavItem>
      </Navigation>
    </div>
  </div>
)

export default FooterPrimary
